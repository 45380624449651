import {base64ToArrayBuffer, arrayBufferToBase64, stringToArrayBuffer, arrayBufferToString} from "./utils";

const log = (...args) => {
	console.log("== %c%s", `color: #BB2AB6; font-weight: bold;`, ...args);
};

export default {
	keyVersion: 1,
	ttl: 1000 * 60, // 5 minutes -> 1 minute
	data: {},
	getTime () {
		const now = new Date();
		const computed = +now + this.data.timeShift;
		log("Computed X-Time", +now, computed, new Date(computed), this.data.timeShift);
		return computed;
	},
	setToken (token) {
		log("token is set", token);
		localStorage.token = token;
	},
	getToken () {
		return localStorage.token;
	},
	resetToken () {
		log("token reset");
		delete localStorage.token;
	},
	async updateServerId (forceUpdate = false) {
		const now = new Date();
		const syncTimeDelta = Math.abs(+now - (this.data.syncTime || 0));
		log("updateServerId?", (syncTimeDelta > this.ttl), now, this.data.syncTime, this.ttl, +now - this.data.syncTime);
		if (forceUpdate || !this.data.serverId || (syncTimeDelta > this.ttl)) {
			const {serverId, serverTime} = await (await fetch("/api/sync", {
				$skipEncryption: true,
			})).json();
			this.data.serverId = serverId;
			this.data.serverTime = serverTime;
			this.data.syncTime = +new Date();
			this.data.timeShift = this.data.serverTime - now;
			const minTransferTimeGap = 20; // время на обмен особщениями которым можно принебречь
			this.data.timeShift -= minTransferTimeGap;
			if (Math.abs(this.data.timeShift) < minTransferTimeGap) {
				this.data.timeShift = 0;
			}
			log("Server time", new Date(serverTime), serverTime);
			log("Sync time", new Date(this.data.syncTime), this.data.syncTime);
			log("Server time shift", this.data.timeShift, "ms");
			this.sharedSecret = null;
			this.serverId = await crypto.subtle.importKey("raw", base64ToArrayBuffer(this.data.serverId),
				{
					name: "ECDH",
					namedCurve: "P-256",
				},
				true,
				[]
			);
		}
	},
	async generateBrowserId () {
		return crypto.subtle.generateKey(
			{
				name: "ECDH",
				namedCurve: "P-256",
			},
			true,
			["deriveKey", "deriveBits"]
		);
	},
	async updateBrowserId (forceUpdate = false) {
		if (forceUpdate || !this.browserId) {
			this.sharedSecret = null;
			if (!localStorage.browserId || (+(localStorage.keyVersion || 0) < this.keyVersion)) {
				log("browserId is not found, generating new one...");
				// generate new key pair
				this.browserId = await this.generateBrowserId();
				this.browserId.verifyKey = await this.convertPublickKeyToVerify(this.browserId.publicKey);
				this.browserId.signKey = await this.convertPrivateKeyToSign(this.browserId.privateKey);

				const publicKey = await crypto.subtle.exportKey("jwk", this.browserId.publicKey);
				const privateKey = await crypto.subtle.exportKey("jwk", this.browserId.privateKey);
				const verifyKey = await crypto.subtle.exportKey("jwk", this.browserId.verifyKey);
				const signKey = await crypto.subtle.exportKey("jwk", this.browserId.signKey);
				/* eslint-disable require-atomic-updates */
				localStorage.keyVersion = this.keyVersion;
				localStorage.browserId = JSON.stringify({
					publicKey,
					privateKey,
					verifyKey,
					signKey,
				});
				/* eslint-enable require-atomic-updates */
			}
			else {
				log("browserId found");
				const stored = JSON.parse(localStorage.browserId);
				this.browserId = {
					publicKey: await crypto.subtle.importKey(
						"jwk",
						stored.publicKey,
						{
							name: "ECDH",
							namedCurve: "P-256",
						},
						true,
						[]
					),
					privateKey: await crypto.subtle.importKey(
						"jwk",
						stored.privateKey,
						{
							name: "ECDH",
							namedCurve: "P-256",
						},
						true,
						["deriveKey", "deriveBits"]
					),
					verifyKey: await crypto.subtle.importKey(
						"jwk",
						stored.verifyKey,
						{
							name: "ECDSA",
							namedCurve: "P-256",
						},
						true,
						["verify"]
					),
					signKey: await crypto.subtle.importKey(
						"jwk",
						stored.signKey,
						{
							name: "ECDSA",
							namedCurve: "P-256",
						},
						true,
						["sign"]
					),

				};
			}
			this.data.browserId = arrayBufferToBase64(await crypto.subtle.exportKey("raw", this.browserId.publicKey));
		}
	},
	async update (forceServer = false, forceBrowser = false) {
		if (this.updating) {
			try {
				await this.updating;
			}
			catch {}
		}
		await (this.updating = Promise.all([
			this.updateServerId(forceServer),
			this.updateBrowserId(forceBrowser),
		]));
		if (!this.sharedSecret) {
			this.sharedSecret = await crypto.subtle.deriveBits(
				{
					name: "ECDH",
					namedCurve: "P-256",
					public: this.serverId,
				},
				this.browserId.privateKey,
				32 * 8,
			);
		}
		return this;
	},
	async initialUpdate () {
		if (this.updating) {
			return this.updating;
		}
		else if (!this.initalUpdatePerformed) {
			await this.update();
			this.initalUpdatePerformed = true;
			return;
		}
	},
	async getRequestKey (url, requestTime) {
		return await crypto.subtle.importKey(
			"raw",
			await crypto.subtle.digest("SHA-256", Uint8Array.from([
				...new Uint8Array(this.sharedSecret),
				...new Uint8Array(stringToArrayBuffer(url)),
				...new Uint8Array(stringToArrayBuffer(requestTime.toString())),
			])),
			{
				name: "AES-CBC",
			},
			false,
			["encrypt", "decrypt"],
		);
	},
	async encrypt (key, data) {
		return await crypto.subtle.encrypt(
			{
				name: "AES-CBC",
				iv: new Uint8Array(16),
			},
			await key,
			await data,
		);
	},
	async decrypt (key, data) {
		return await crypto.subtle.decrypt(
			{
				name: "AES-CBC",
				iv: new Uint8Array(16),
			},
			await key,
			await data,
		);
	},
	async convertPrivateKeyToSign (privateKey) {
		const jwk = await crypto.subtle.exportKey("jwk", await privateKey);
		jwk.key_ops = ["sign"];
		return crypto.subtle.importKey(
			"jwk",
			jwk,
			{
				name: "ECDSA",
				namedCurve: "P-256",
			},
			true,
			["sign"]
		);
	},
	async convertPublickKeyToVerify (publicKey) {
		const jwk = await crypto.subtle.exportKey("jwk", publicKey);
		jwk.key_ops = ["verify"];
		return crypto.subtle.importKey(
			"jwk",
			jwk,
			{
				name: "ECDSA",
				namedCurve: "P-256",
			},
			true,
			["verify"]
		);
	},
	async sign (privateKey, data) {
		// const dataHash = await crypto.subtle.digest("SHA-256", await data);
		return await crypto.subtle.sign(
			{
				name: "ECDSA",
				hash: {name: "SHA-256"},
			},
			await privateKey,
			data,
		);
	},
	async verify (publicKey, data, signature) {
		// const dataHash = await crypto.subtle.digest("SHA-256", await data);
		return await crypto.subtle.verify(
			{
				name: "ECDSA",
				hash: {name: "SHA-256"},
			},
			await publicKey,
			await signature,
			data
		);
	},
	async test (jsonData) {
		const response = await fetch("/api/web/test", {
			method: "POST",
			$noToken: true,
			body: JSON.stringify(jsonData),
		});
		console.log(response.headers.get("Content-Type"));
		const data = await response.json();
		console.log("data", data, response.ok);
	},
	async testSigning () {
		console.group("CHECK SIGNING");
		// await transportCtrl.update();
		const pair1 = await this.generateBrowserId();
		pair1.signKey = await this.convertPrivateKeyToSign(pair1.privateKey);
		pair1.verifyKey = await this.convertPublickKeyToVerify(pair1.publicKey);
		console.log("pair1", pair1);
		const pair2 = await this.generateBrowserId();
		pair2.signKey = await this.convertPrivateKeyToSign(pair2.privateKey);
		pair2.verifyKey = await this.convertPublickKeyToVerify(pair2.publicKey);
		console.log("pair2", pair2);
		const data = JSON.stringify({a: 123});
		const dataPrep = stringToArrayBuffer(data);

		const signature = await this.sign(pair1.signKey, dataPrep);
		console.log("x-sign", arrayBufferToBase64(signature));
		console.log("verifyResult (should be true)", await this.verify(pair1.verifyKey, dataPrep, signature));
		console.log("verifyResult (should be false)", await this.verify(pair2.verifyKey, dataPrep, signature));
		console.groupEnd("CHECK SIGNING");
	},
};
