import {nextTick, inject} from "vue";
import {createI18n} from "vue-i18n";

export const SUPPORT_LOCALES = ["uk", "ru", "en"];

const langCtrlSymbol = Symbol("langCtrl");

export async function setupI18n ($, options = {locale: "uk", fallbackLocale: "uk"}) {
	const langCtrl = {
		i18n: createI18n(options),
		get locale () {
			return langCtrl.i18n.global.locale.value;
		},
		async setLocale (locale) {
			return setI18nLanguage($, locale || options.locale);
		},
	};


	$.langCtrl = langCtrl;
	$.i18n = langCtrl.i18n;
	await langCtrl.setLocale();

	$.app.provide(langCtrlSymbol, langCtrl);

	return langCtrl;
}

export function useLangCtrl () {
	return inject(langCtrlSymbol);
}


async function setI18nLanguage ($, locale) {
	$.cookies.set("locale", locale, {
		path: import.meta.env.VITE_baseUrl,
	});
	const messages = await loadLocaleMessages(locale);
	$.i18n.global.setLocaleMessage(locale, messages?.default);
	if ($.i18n.mode === "legacy") {
		$.i18n.global.locale = locale;
	}
	else {
		$.i18n.global.locale.value = locale;
	}
}

export async function loadLocaleMessages (locale) {
	let localeFile;
	try {
		localeFile = await import(`./i18n/${locale}.json`);
	}
	catch (error) {
		console.log(error);
	}
	return localeFile;
}
