import IMask from "imask";

export default {
	name: "imask",

	mounted: (el, {value: options}) => {
		if (!options) {return;}
		initMask(el, options);
	},

	updated: (el, {value: options}) => {
		if (options) {
			if (el.maskRef) {
				el.maskRef.updateOptions(options);
				if (el.value !== el.maskRef.value) {
					el.maskRef._onChange();
				}
			}
			else {
				initMask(el, options);
			}
		}
		else {
			destroyMask(el);
		}
	},

	unmounted: (el) => {
		destroyMask(el);
	},
};

function fireEvent (el, eventName, data) {
	let e = document.createEvent("CustomEvent");
	e.initCustomEvent(eventName, true, true, data);
	el.dispatchEvent(e);
}

function initMask (el, opts) {
	el.maskRef = IMask(el, opts)
		.on("accept", () => fireEvent(el, "accept", el.maskRef))
		.on("complete", () => fireEvent(el, "complete", el.maskRef));

	setTimeout(() => {
		fireEvent(el, "init", el.maskRef);
	});
}

function destroyMask (el) {
	if (el.maskRef) {
		el.maskRef.destroy();
		delete el.maskRef;
	}
}
