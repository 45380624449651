
export const line = (x, {x1 = 0, y1 = 0, x2 = 1, y2 = 1, easingFn}) => {
	if (easingFn) {
		return line(easingFn(line(x, {x1, y1: 0, x2, y2: 1})), {x1: 0, y1, x2: 1, y2});
	}
	return x2 === x1 ? y1 : ((x - x1) / (x2 - x1)) * (y2 - y1) + y1;
};
export const lineLimit = (x, {x1 = 0, y1 = 0, x2 = 1, y2 = 1, easingFn}) => line(Math.max(Math.min(x1, x2), Math.min(Math.max(x1, x2), x)), {x1, y1, x2, y2, easingFn});
export const lerp = line;
export const lerpLimit = lineLimit;
export const uuid = () => Array.from(crypto.getRandomValues(new Uint32Array(4))).map(n => n.toString(16)).join("-");
export const round = (value, acc = 100000) => Math.round(value * acc) / acc;
export const getMax = (...args) => Math.max(...args.filter(i => i != null));
export const getMin = (...args) => Math.min(...args.filter(i => i != null));
export const clamp = (value, min, max) => Math.max(min > max ? max : min, Math.min(min > max ? min : max, value));
export const cycle = (value, max = 1, min = 0) => (value = value - min, (value < 0 ? ((max + (value % max)) % max) : value % max) + min);
export const clone = (obj) => JSON.parse(JSON.stringify(obj));
export const toArray = (obj) => Object.keys(obj).map(key => ({key, value: obj[key]}));
export const each = (list, fn) => Object.keys(list).map(key => fn(list[key], key));
export const randomFloat = (min, max) => Math.random() * (max - min) + min;
export const randomInt = (min, max) => Math.floor(min + Math.random() * (max + 1 - min));
export const flattenDeep = (array, parent = []) =>
	array.reduce((parent, item) => (Array.isArray(item) ? flattenDeep(item, parent) : parent.push(item), parent), parent);
export const extend = (target, ...sources) =>
	(sources.forEach(source => Object.defineProperties(target, Object.keys(source)
		.reduce((descriptors, key) => (descriptors[key] = Object.getOwnPropertyDescriptor(source, key), descriptors), {}))), target);

export const cloneDeep = (source) => {
	if (source && typeof source === "object") {
		if (Array.isArray(source)) {
			return source.map(cloneDeep);
		}
		const target = {};
		Object.keys(source).forEach(key => {
			target[key] = cloneDeep(source[key]);
		});
		return target;
	}
	return source;
};

export function cloneRect (rect) {
	return {
		top: rect.top,
		right: rect.right,
		bottom: rect.bottom,
		left: rect.left,
		width: rect.width,
		height: rect.height,
		x: rect.x,
		y: rect.y,
	};
}

export function rectToOffsetParent (r, rect) {

	return Object.assign(r, {
		left: r.left - rect.left,
		right: rect.right - r.right,
		top: r.top - rect.top,
		bottom: rect.bottom - r.bottom,
		width: r.width,
		height: r.height,
	});
}


export function intersect (rect1, rect2) {
	return !(
		rect1.right < rect2.left ||
		rect1.left > rect2.right ||
		rect1.bottom < rect2.top ||
		rect1.top > rect2.bottom
	);
}

export function intersectionRect (rect1, rect2) {
	return intersect(rect1, rect2) && {
		left: Math.max(rect1.left, rect2.left),
		top: Math.max(rect1.top, rect2.top),
		right: Math.min(rect1.right, rect2.right),
		bottom: Math.min(rect1.bottom, rect2.bottom),
	};
}

export const getViewportRect = () => {
	return {
		left: 0,
		right: window.innerWidth || document.documentElement.clientWidth,
		top: 0,
		bottom: window.innerHeight || document.documentElement.clientHeight,
		width: window.innerWidth || document.documentElement.clientWidth,
		height: window.innerHeight || document.documentElement.clientHeight,
	};
};

const loadScripts = new WeakMap();

export const loadScript = (src, exports) => {
	let script = document.querySelector(`script[src="${src}"]`);
	let loading;
	if (!script) {
		script = document.createElement("script");
		script.setAttribute("data-dynamic-script", "loading");
		loading = new Promise((resolve, reject) => {
			script.addEventListener("load", event => {
				script.setAttribute("data-dynamic-script", "ready");
				if (exports) {
					if (Array.isArray(globalThis[exports])) {
						resolve(exports.map(key => globalThis[key]));
					}
					else {
						resolve(globalThis[exports]);
					}
				}
				else {
					resolve();
				}
			});
			script.addEventListener("error", event => {
				script.remove();
				reject();
			});
			script.src = src;
			document.querySelector("head").appendChild(script);
		});
		loadScripts.set(script, loading);
		return loading;
	}
	loading = loadScripts.get(script);
	return loading;
};

export const toBoolean = (val) => {
	if (typeof val === "string") {
		return val === "true";
	}
	return !!val;
};

export function phoneMask (val) {
	const phone = val.split("").reverse();
	const newPhone = [];
	for (let i = 0; i < phone.length; i++) {
		newPhone.push(phone[i]);
		if (i % 2 === 0 && i < 9 && i !== 0) {
			newPhone.push(" ");
		}
	}
	return newPhone.reverse().join("");
}
