let observer;
let el1;
let el2;
export default {
	name: "appear",
	mounted: (el, {value: options, arg}) => {
		if (!options) {return;}
		if (!observer && !el1 && !el2) {
			el1 = el;
			initScrollWatcher(el, options, arg);
		}
		else {
			el2 = el;
			observer.observe(el);
		}

	},
	unmounted: (el) => {
		observer.disconnect();
	},
};

function initScrollWatcher (el, func, delay = 2000) {
	let options = {
		root: null,
		rootMargin: `0px`,
		threshold: [1],
	};
	let timerId;
	let callback = function (entries) {
		entries.forEach(item => {
			let target = item.target;
			let child = target.children;
			if (child.length) {
				child = child[0];
			}
			else {
				child = null;
			}
			if (item.isIntersecting) {
				child.style.opacity = 1;
				timerId = setTimeout(() => {
					func();
				}, delay);
			}
			else {
				child.style.opacity = 0;
				clearTimeout(timerId);
			}
		});
	};
	observer = new IntersectionObserver(callback, options);
	observer.observe(el);
}
