import interceptFetch from "./intercept-fetch";
import {stringToArrayBuffer, arrayBufferToString, arrayBufferToBase64} from "./utils";
import transportCtrl from "./transport";
import {default as getCaptchaToken} from "./captcha";
import { inject } from 'vue'

const csrfToken = document.querySelector(`meta[name="csrf-token"]`)?.content;
// INTERCEPT FETCH
export function initInterceptors ($) {
	interceptFetch.before.push(async (ctrl) => {
		const {url, request} = ctrl;
		const env = $.app.config.globalProperties.env;
		request.headers = request.headers || {};
		request.headers.lang = $.locale;

		/** Setup of static call **/
		if (env.staticApiFetch && url.startsWith('/s3-cache')) {
			const date = new Date();
			/* truncate s3-cache prefix and append no cache suffix */
			ctrl.url = ctrl.url.replace('/s3-cache', env.staticApiUrl) + `?cache=${date.valueOf()}`;
			/* setup content type */
			request.headers["content-type"] = "application/json";
		}

		if (url.startsWith("/api")) {
			ctrl.url = env.backApiUrl + ctrl.url;
			if (!request.$skipEncryption) {
				/* eslint-disable require-atomic-updates */
				try {
					if (request.$captcha || request.$captchaOneTime) {
						let action = request.$captcha ?? request.$captchaOneTime;
						action = typeof action === "string" ? action : "homepage";
						request.headers.recaptcha = await getCaptchaToken({
							key: import.meta.env.VUE_APP_CAPTCHA_KEY,
							action,
							url,
							forceNew: true,
						});
						console.log("request.headers.recaptcha", request.headers.recaptcha);
					}
					await transportCtrl.update();

					const requestTime = transportCtrl.getTime();
					ctrl.data.encrypted = true;
					ctrl.data.requestKey = await transportCtrl.getRequestKey(url.split("?")[0], requestTime);
					request.headers["x-browser-id"] = transportCtrl.data.browserId;
					request.headers["x-time"] = requestTime;

					if (!request.headers["content-type"]) {
						request.headers["content-type"] = "application/octet-stream";
					}

					const token = transportCtrl.getToken();
					if (!request.$noToken && token) {
						request.headers["x-token"] = arrayBufferToBase64(await transportCtrl.encrypt(ctrl.data.requestKey, stringToArrayBuffer(token)));
					}
					if (request.$sign) {
						const data = typeof request.body === "string" || request.body == null ? stringToArrayBuffer(request.body || "") : request.body;
						const signature = await transportCtrl.sign(transportCtrl.browserId.signKey, data);
						request.headers["x-sign"] = arrayBufferToBase64(signature);
					}
					if (request.body && !(request.body instanceof FormData)) {
						ctrl.data.bodyEncrypted = true;

						request.body = await transportCtrl.encrypt(ctrl.data.requestKey, typeof request.body === "string" ? stringToArrayBuffer(request.body) : request.body);
						request.headers = request.headers || {};
					}
					// console.log("request", request);
					// from old interseptors
					if (csrfToken) {
						request.headers["x-csrf-token"] = csrfToken;
					}
				}
				finally {
					try {
						const beforeSend = ctrl?.original?.originalRequest?.$beforeSend;
						if (typeof beforeSend === "function") {
							beforeSend(request, ctrl);
						}
					}
					catch {
					}
				}
				/* eslint-enable require-atomic-updates */
			}
		}
	});

	interceptFetch.after.push(async (ctrl) => {
		// console.log(ctrl.response.status);
		if (ctrl.response.status === 204) {
			// ctrl.response = new Response(null, {
			// 	status: ctrl.response.status,
			// 	statusText: ctrl.response.statusText,
			// });
		}
		else if (ctrl.data.encrypted) {
			const data = arrayBufferToString(await transportCtrl.decrypt(ctrl.data.requestKey, await ctrl.response.arrayBuffer()));
			// console.log("decrypted", data);
			const body = new Blob([data], {type: "application/json;charset=UTF-8", encoding: "UTF-8"});
			/* eslint-disable require-atomic-updates */
			if (ctrl.url === "/api/web/test") {
				const requestDecrypted = arrayBufferToString(await transportCtrl.decrypt(ctrl.data.requestKey, ctrl.request.body));
				const responseDecrypted = JSON.stringify(JSON.parse(data)?.request);
				const assertion = requestDecrypted === responseDecrypted;
				if (assertion) {
					console.info("шифрование прошло проверку, запрос и ответ совпадают");
				}
				else {
					console.assert(assertion, "шифрование не прошло проверку, запрос и ответ не совпадают");
				}
			}
			ctrl.response = new Response(body, {
				status: ctrl.response.status,
				statusText: ctrl.response.statusText,
			});
			/* eslint-enable require-atomic-updates */
		}
	});
}
