import toJson from "json-stringify-safe";

export const apis = new Map();

/** TODO: support js object serialization of Date,Map,Set,File,Blob... */
let globalSSRContext = null;

export function setSsrContext (value) {
	globalSSRContext = value;
}

export function registerApi (name, handler, settings = {}) {
	const apiHandler = function (params, context, ssrContext) {
		return handler(params, context, ssrContext);
	};
	const createApiCall = (_context, _ssrContext) => {
		const apiCall = async (params, context, ssrContext) => {
			if (import.meta.env.SSR) {
				return apiHandler(params, context || _context, ssrContext || _ssrContext || globalSSRContext);
			}
			else {
				const prettyName = settings?.getName?.(params);
				return fetch(prettyName, {
					...params,
				})
					.then(response => {
						return response.json();
					});
			}
			// const prettyName = settings?.getName?.(params);
			// return fetch(prettyName, {
			// 		...params,
			// 	})
			// 	.then(response => {
			// 		return response.json();
			// 	});
		};

		apiCall.setContext = (value) => {
			_context = value;
		};

		apiCall.setSsrContext = (value) => {
			_ssrContext = value;
		};

		apiCall.useContext = createApiCall;

		return apiCall;
	};

	const apiCall = createApiCall();

	apis.set(name, apiCall);

	return apiCall;
}
