export function stringToArrayBuffer (string, latin1 = false) {
	if (latin1) {
		return Uint8Array.from(string, x => x.charCodeAt(0));

	}
	return new TextEncoder().encode(string);
}

export function arrayBufferToString (arrayBuffer, latin1 = false) {
	if (latin1) {
		return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
	}
	return new TextDecoder().decode(arrayBuffer);
}

export function base64ToArrayBuffer (string = "") {
	if (string.length % 4 != 0) {
		string += ("===").slice(0, 4 - (string.length % 4));
	}
	const escaped = string.replace(/-/g, "+").replace(/_/g, "/");
	return stringToArrayBuffer(atob(escaped), true);
}

export function arrayBufferToBase64 (arrayBuffer) {
	const unescaped = arrayBufferToString(arrayBuffer, true);
	return btoa(unescaped).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
}

export function arrayBufferToBase64Payment (arrayBuffer) {
	const unescaped = arrayBufferToString(arrayBuffer, true);
	return btoa(unescaped).replace(/\+/g, "-").replace(/\//g, "_");
}

export function numberToArrayBuffer (value, size = Math.ceil(Math.log2(value + 1) / 8)) {
	const view = new DataView(new ArrayBuffer(size));
	for (let i = size - 1; i >= 0; i--) {
		view.setUint8(i, value % 256);
		value = value >> 8;
	}
	return view.buffer;
}
