export default {
	lightenDarkenColor (hexCode, amt) {

		let usePound = false;

		if (hexCode[0] == "#") {
			hexCode = hexCode.slice(1);
			usePound = true;
		}

		let num = parseInt(hexCode, 16);

		let r = (num >> 16) + amt;

		if (r > 255) {r = 255;}
		else if (r < 0) {r = 0;}

		let b = ((num >> 8) & 0x00FF) + amt;

		if (b > 255) {b = 255;}
		else if (b < 0) {b = 0;}

		let g = (num & 0x0000FF) + amt;

		if (g > 255) {g = 255;}
		else if (g < 0) {g = 0;}

		return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
	},
	convertHexToRGBA (hexCode, opacity = 1) {
		let hex = hexCode.replace("#", "");

		if (hex.length === 3) {
			hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
		}

		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		/* Backward compatibility for whole number based opacity values. */
		if (opacity > 1 && opacity <= 100) {
			opacity = opacity / 100;
		}

		return `rgba(${r},${g},${b},${opacity})`;
	},
	lightColor (color) {
		const hsl = this.hexToHSL(color);
		hsl[2] = 92;
		return this.hslToHex(hsl[0], hsl[1], hsl[2]);
	},
	hexToHSL (hex) {
		hex = hex.replace(/^#/, "");
		const r = parseInt(hex.slice(0, 2), 16) / 255;
		const g = parseInt(hex.slice(2, 4), 16) / 255;
		const b = parseInt(hex.slice(4, 6), 16) / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);

		const l = (max + min) / 2;

		let s = 0;
		if (max !== min) {
			s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
		}

		let h;
		if (max === min) {
			h = 0;
		}
		else {
			switch (max) {
				case r:
					h = (g - b) / (max - min);
					break;
				case g:
					h = 2 + (b - r) / (max - min);
					break;
				case b:
					h = 4 + (r - g) / (max - min);
					break;
			}
			h *= 60;
			if (h < 0) {
				h += 360;
			}
		}

		return [h, s * 100, l * 100];
	},
	hslToHex (h, s, l) {
		h /= 360;
		s /= 100;
		l /= 100;

		let r; let g; let b;

		if (s === 0) {
			r = g = b = l;
		}
		else {
			const hueToRGB = function hueToRGB (p, q, t) {
				if (t < 0) {t += 1;}
				if (t > 1) {t -= 1;}
				if (t < 1 / 6) {return p + (q - p) * 6 * t;}
				if (t < 1 / 2) {return q;}
				if (t < 2 / 3) {return p + (q - p) * (2 / 3 - t) * 6;}
				return p;
			};

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;

			r = Math.round(hueToRGB(p, q, h + 1 / 3) * 255);
			g = Math.round(hueToRGB(p, q, h) * 255);
			b = Math.round(hueToRGB(p, q, h - 1 / 3) * 255);
		}

		r = r.toString(16).padStart(2, "0");
		g = g.toString(16).padStart(2, "0");
		b = b.toString(16).padStart(2, "0");

		return `#${r}${g}${b}`;
	},
	blackOrWhite (bg) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bg);
		const r = parseInt(result[1], 16);
		const g = parseInt(result[2], 16);
		const b = parseInt(result[1], 16);
		const c = r * g * b;
		const base = 16777215; // ffffff
		return c < base / 2 ? "#ffffff" : "#000000";
	},
	avatarColorMap: new Map([
		["A", "#9C27B0"],
		["B", "#673AB7"],
		["C", "#3F51B5"],
		["D", "#2196F3"],
		["E", "#2196F3"],
		["F", "#00BCD4"],
		["G", "#009688"],
		["H", "#009688"],
		["I", "#8BC34A"],
		["J", "#CDDC39"],
		["K", "#CDDC39"],
		["L", "#FFC107"],
		["M", "#FF9800"],
		["N", "#FF9800"],
		["O", "#795548"],
		["P", "#9E9E9E"],
		["Q", "#607D8B"],
		["R", "#F44336"],
		["S", "#E91E63"],
		["T", "#CE93D8"],
		["U", "#CE93D8"],
		["V", "#9FA8DA"],
		["W", "#90CAF9"],
		["X", "#90CAF9"],
		["Y", "#90CAF9"],
		["Z", "#80CBC4"],
		["А", "#9C27B0"],
		["Б", "#673AB7"],
		["В", "#3F51B5"],
		["Г", "#2196F3"],
		["Д", "#2196F3"],
		["Е", "#00BCD4"],
		["Ё", "#009688"],
		["Ж", "#009688"],
		["З", "#8BC34A"],
		["И", "#CDDC39"],
		["Й", "#CDDC39"],
		["К", "#FFC107"],
		["Л", "#FF9800"],
		["М", "#FF9800"],
		["Н", "#795548"],
		["О", "#9E9E9E"],
		["П", "#607D8B"],
		["Р", "#F44336"],
		["С", "#E91E63"],
		["Т", "#CE93D8"],
		["У", "#CE93D8"],
		["Ф", "#9FA8DA"],
		["Х", "#90CAF9"],
		["Ц", "#90CAF9"],
		["Ч", "#90CAF9"],
		["Ш", "#80CBC4"],
		["Щ", "#80CBC4"],
		["Э", "#80CBC4"],
		["Ю", "#80CBC4"],
		["Я", "#80CBC4"],
		["0", "#2196F3"],
		["1", "#FF9800"],
		["2", "#8BC34A"],
		["3", "#607D8B"],
		["4", "#CE93D8"],
		["5", "#80CBC4"],
		["6", "#9C27B0"],
		["7", "#3F51B5"],
		["8", "#009688"],
		["9", "#F44336"],
	]),
	getAvatarColorFromMap (s) {
		return this.avatarColorMap.get(s) || this.avatarColorMap.get("A");
	},
};
