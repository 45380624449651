import {loadScript} from "#app/utils/common";

const TTL = 60 * 1000;
const tokens = [];
const updateIntervals = [];

const callbacks = {};

async function getToken ({key, action, forceNew = false, el, url}) {
	function removeTokenObject (tokenObject) {
		const idx = tokens.indexOf(tokenObject);
		if (idx >= 0) {
			tokens.splice(idx, 1);
		}
	}
	if (!el) {
		el = document.querySelector(`#captcha-container`);
		if (!el) {
			el = document.createElement("div");
			el.id = "captcha-container";
			document.body.appendChild(el);
		}
	}

	const grecaptcha = await loadScript(`https://www.google.com/recaptcha/api.js?render=${el ? "explicit" : key}`, "grecaptcha");
	await new Promise(resolve => grecaptcha.ready(resolve));

	let tokenObject = tokens.find(tokenObj => (tokenObj.key === key && tokenObj.action == action));
	if (!forceNew && tokenObject && !tokenObject.isExpired()) {
		await tokenObject.loading;
		return tokenObject;
	}
	else {
		removeTokenObject(tokenObject);
	}
	console.log("grecaptcha", grecaptcha);

	function tokenFn () {
		return this.token;
	}

	let captchaId = key;
	// console.log("render captcha", el, url);

	if (el) {
		captchaId = el.dataset.captchaId;
		if (captchaId == null) {
			captchaId = grecaptcha.render(el, {
				"sitekey": key,
				"size": "invisible",
				"type": "image",
				"badge": "inline",
				callback: (result) => {
					callbacks?.[captchaId]?.(result);
				},
			});
			el.dataset.captchaId = captchaId;
		}
		else {
			grecaptcha.reset(captchaId);
		}
	}

	const loading = new Promise((resolve, reject) => {
		callbacks[captchaId] = (result) => {
			resolve(result);
		};
	});

	grecaptcha.execute(captchaId, {action});
	tokenObject = {
		key,
		action,
		token: "loading",
		created: new Date(),
		isExpired (ttl = TTL) {
			const now = +new Date();
			return now > (+this.created + ttl);
		},
		loading, // {action}),
		toString: tokenFn,
		toJSON: tokenFn,
		valueOf: tokenFn,
	};
	tokens.push(tokenObject);
	await tokenObject
		.loading
		.then(token => {
			tokenObject.token = token;
			console.log(new Date().toLocaleTimeString(document.documentElement.lang), "New recaptcha token was obtained", token);
		})
		.catch(error => {
			tokenObject.token = "error-while-getting-token";
			console.error("Recaptcha error", error);
		});

	return tokenObject;
}

export default getToken;
export async function disableCaptcha (key) {
	const grecaptcha = await loadScript(`https://www.google.com/recaptcha/api.js?render=${key}`, "grecaptcha");
	grecaptcha.reset(key);
}

export async function startUpdate (key, action) {
	if (!updateIntervals.find(item => (item.key === key && item.action == action))) {
		getToken(key, action);
		updateIntervals.push({
			key,
			action,
			interval: setInterval(() => {
				getToken(key, action);
			}, 1000),
		});
	}
}

export async function finishUpdate (key, action) {
	const updateInterval = updateIntervals.find(item => (item.key === key && item.action == action));
	if (updateInterval) {
		clearInterval(updateInterval.interval);
		updateIntervals.splice(updateIntervals.indexOf(updateInterval), 1);
	}
}
