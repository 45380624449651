
import {reactive} from "vue";

export function createStateAccessor (state, settings = {}) {
	const {
		methods = {},
		getters = ["*"],
		setters = ["*"],
		onGet = null,
		onSet = null,
		onCall = null,
		context = {},
	} = settings;

	const $state = reactive(state);

	function apply (changes = {}) {
		// console.log("apply changes", changes, "to", state);
		Object.entries(changes)
			.forEach(([key, value]) => {
				state[key] = changes[key];
			});
	}

	let initialState = {};

	const createWithContext = (accessorContext = {}) => {

		const accessor = new Proxy($state, {
			get (target, propertyKey, context) {
				let value;

				if (propertyKey === "$apply") {
					return apply;
				}

				if (propertyKey === "$context") {
					return accessorContext;
				}

				if (propertyKey === "$bindContext") {
					return (accessorContext) => createWithContext(accessorContext);
				}

				if (propertyKey === "$state") {
					return state;
				}

				if (propertyKey === "$applyInitial") {
					return (changes = {}) => {
						initialState = changes;
						return apply(initialState);
					};
				}

				if (propertyKey === "$initial") {
					return initialState;
				}

				const method = methods[propertyKey];
				if (typeof method === "function") {
					value = new Proxy(method, {
						apply (target, thisArg, argumentsList) {
							const value = Reflect.apply(target, thisArg, [accessor, ...argumentsList]);
							onCall?.(target, propertyKey, value, thisArg, argumentsList);
							return value;
						},
					});
				}
				else {
					value = $state[propertyKey]; // Reflect.get(target, propertyKey, context);
				}
				onGet?.(target, propertyKey, value, context);
				return value;
			},
			set (target, propertyKey, value, context) {
				// const result = Reflect.set(target, propertyKey, value, context);
				$state[propertyKey] = value;
				onSet?.(target, propertyKey, value, context);
				return true;
			},
		});

		return accessor;
	};

	return createWithContext(context);
}
