let lastScrollY;
export default {
	name: "scroll",
	mounted: (el, {value: options}) => {
		if (!options) {return;}
		lastScrollY = window.pageYOffset || document.documentElement.scrollTop;
		window.onscroll = (e) => {
			calcScroll(e, el, options);
			repeatCall(e, el, options, 15);
		};
	},
	unmounted: (el) => {
		window.onscroll = null;
	},
};
let isRepeated = false;
function repeatCall (e, el, options, n) {
	if (n === 0 || isRepeated) {
		return;
	}
	isRepeated = true;
	setTimeout(() => {
		isRepeated = false;
		calcScroll(e, el, options);
		repeatCall(e, el, options, n - 1);
	}, 50);
}

function calcScroll (e, el, options) {
	const elSize = el.getBoundingClientRect();
	let currentScrollY = window.pageYOffset || document.documentElement.scrollTop;
	let isDown;
	if (currentScrollY > lastScrollY) {
		isDown = true;
	}
	else {
		isDown = false;
	}
	// console.log(isDown, elSize, window.scrollY);
	lastScrollY = currentScrollY;
	let offset = elSize.height - window.scrollY < options.minHeight ? elSize.height - options.minHeight : window.scrollY;
	if (isDown && elSize.height - Math.abs(elSize.top) < options.minHeight || !isDown && elSize.height - window.scrollY < options.minHeight || window.scrollY <= 0 && offset < 0) {
		// console.log("offset", offset, elSize.height - window.scrollY);
		return;
	}
	// console.log("calcScroll", offset * -1, elSize.height - Math.abs(elSize.top), isDown);
	el.style.transform = `translateY(${offset * -1}px)`;

}
